import cn from "classnames";

import styles from "./common-button.module.scss";

interface CommonButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  icon?: string;
  children: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  className?: string;
  attrData?: string;
}

const CommonButton = ({
  type,
  children,
  icon,
  onClick,
  disabled,
  className,
  attrData
}: CommonButtonInterface) => {
  return (
    <button
      type={type ?? "button"}
      className={cn(styles["secondary"], className, {
        [styles["secondary--disabled"]]: disabled
      })}
      onClick={onClick}
      disabled={disabled}
      data-attr={attrData}
    >
      {icon && (
        <div className={styles["secondary__icon"]}>
          <img src={icon} alt={children + "-icon"} />
        </div>
      )}
      {children}
    </button>
  );
};

export default CommonButton;
