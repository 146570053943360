import CommonButton from "components/buttons/CommonButton/CommonButton";

import DownloadIcon from "assets/icons/download.svg";
import PlayIcon from "assets/icons/play.svg";

import styles from "./map-action-btns.module.scss";

interface MapActionBtnsInterface {
  icon?: string;
  inferenceIcon?: string;
  action?: () => void;
  isLayerInit?: boolean;
  inferenceAction?: () => void;
}

const MapActionBtns = ({
  icon,
  inferenceIcon,
  action,
  isLayerInit,
  inferenceAction
}: MapActionBtnsInterface) => {
  return (
    <div className={styles["map-action-buttons-container"]}>
      <div className={styles["map-action-buttons-container__row"]}>
        {action && (
          <CommonButton
            type="button"
            icon={icon ?? DownloadIcon}
            onClick={action}
            disabled={isLayerInit}
          >
            {"Add"}
          </CommonButton>
        )}
        {inferenceAction && (
          <CommonButton
            type="button"
            onClick={inferenceAction}
            icon={inferenceIcon ?? PlayIcon}
            disabled={!isLayerInit}
          >
            Start
          </CommonButton>
        )}
      </div>
    </div>
  );
};

export default MapActionBtns;
