const styles = `
* {
    font-family: 'Inter';
}

.pdf {
    padding: 40px;
    background-color: white;
}

.pdf__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__info {
    font-size: 12px;
    line-height: 28px;
}

.pdf__title {
    margin: 80px auto 46px;
    text-align: center;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: -0.8px;
    font-weight: 600;
}

.pdf__reporter {
    text-align: center;
    line-height: 28px;

    span {
        margin-left: 16px;
        font-weight: 700;
        color: #09090B
    }
}

.pdf__area-of-interest {
    margin-top: 46px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.section {
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section__title {
    margin-bottom: 26px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.5px;
}

.section__input {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 5px;

    label,
    input,
    textarea {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
    }

    input,
    textarea {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px #E4E4E7 solid;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        outline: none;

    }

    input:hover,
    textarea:hover {
        border-color: #d6d6d6;
    }

    label {
        padding: 8px 0;
    }
}

.section__table {
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    th {
        font-weight: 600;
        border-top: 1px #E4E4E7 solid;
    }

    th,
    td {
        padding: 8px 16px;
        border-right: 1px #E4E4E7 solid;
        border-bottom: 1px #E4E4E7 solid;
        color: #09090B;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
    }

    th:first-of-type,
    td:first-of-type {
        border-left: 1px #E4E4E7 solid;
    }

    .text-left {
        text-align: left;
    }
}


@media (max-width: 992px) {
    .pdf {
        padding: 10px;
    }
    .section {
        overflow: auto;
    }
    .section__input {
        width: 100%;
    }
    .section__table {
        th, td {
            padding: 8px 4px;
        }
    
        .only-first-letter-mobile::first-letter {
            font-size: 14px;
        }

        .only-first-letter-mobile {
            font-size: 0;
        }
    }
}
`;

export default styles;
