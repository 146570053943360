import cn from "classnames";
import { useCallback, useState } from "react";

import BurgerMenu from "components/BurgerMenu/BurgerMenu";

import styles from "./burger.module.scss";

const BurgerButton = () => {
  const [isActive, setIsActive] = useState(false);
  const onClick = useCallback(
    (val) => () => {
      setIsActive(val);
    },
    []
  );

  return (
    <div className={styles["wrapper"]}>
      <div
        className={cn(styles["burger"], {
          [styles["burger--active"]]: isActive
        })}
        onClick={() => {
          setTimeout(() => {
            onClick(!isActive)();
          }, 0);
        }}
      >
        <div className={styles["line"]}></div>
        <div className={styles["line"]}></div>
        <div className={styles["line"]}></div>
      </div>
      <BurgerMenu isActive={isActive} onClick={onClick(false)} />
    </div>
  );
};

export default BurgerButton;
