import { takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import {
  addProject,
  getProjects,
  removeProject,
  updateProject
} from "services/api/project";
import { actions } from "./ducks";

function* fetchProjects({ payload }) {
  const request = getProjects;

  const { fetchProjectsSuccess: setSuccess, fetchProjectsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createProject({ payload }) {
  const request = addProject;

  const { createProjectSuccess: setSuccess, createProjectError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* renameProject({ payload }) {
  const request = updateProject;

  const { renameProjectSuccess: setSuccess, renameProjectError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* deleteProject({ payload }) {
  const request = removeProject;

  const { deleteProjectSuccess: setSuccess, deleteProjectError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectsRequest, fetchProjects);
  yield takeLatest(actions.createProjectRequest, createProject);
  yield takeLatest(actions.renameProjectRequest, renameProject);
  yield takeLatest(actions.deleteProjectRequest, deleteProject);
}
