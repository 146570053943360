import { useCallback, useEffect, useRef } from "react";

import CommonButton from "components/buttons/CommonButton/CommonButton";

import UploadIcon from "assets/icons/upload.svg";

import styles from "./upload-files.module.scss";

interface UploadFilesInterface {
  handleFileChange: (event: any) => void;
  isMultiple?: boolean;
  title?: string;
  className?: string;
}
const UploadFiles = ({
  handleFileChange,
  isMultiple,
  title,
  className
}: UploadFilesInterface) => {
  const ref = useRef<HTMLInputElement>();

  const onChooseFile = useCallback(() => {
    ref.current.click();
  }, []);

  useEffect(() => {
    const handlePaste = async (e) => {
      const item = Array.from(e.clipboardData.items).find((x) =>
        /^image\//.test((x as DataTransferItem).type)
      ) as DataTransferItem;
      if (item) {
        const blob = item.getAsFile();
        const file = new File([blob], "pasted-image", {
          type: blob.type
        });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        if (ref.current) {
          ref.current.files = dataTransfer.files;
          const event = new Event("change", { bubbles: true });
          ref.current.dispatchEvent(event);
        }
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div className={className}>
      <CommonButton type="button" icon={UploadIcon} onClick={onChooseFile}>
        {title ?? "Upload"}
      </CommonButton>
      <input
        className={styles["upload__input"]}
        type="file"
        id="files"
        aria-label="files"
        onChange={handleFileChange}
        multiple={isMultiple}
        ref={ref}
      />
    </div>
  );
};

export default UploadFiles;
