import cn from "classnames";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";

import styles from "./range.module.scss";

interface RangeInterface {
  min?: number;
  max?: number;
  value?: any;
  isDisabled?: boolean;
  onChange?: (val) => void;
  containerClassName?: string;
}

const Range = ({
  value,
  onChange,
  min,
  max,
  isDisabled,
  containerClassName
}: RangeInterface) => {
  return (
    <div
      className={cn(
        styles["range"],
        { [styles["range__disabled"]]: isDisabled },
        containerClassName
      )}
    >
      <Slider
        className={styles["range__slider"]}
        value={value}
        onChange={onChange}
        min={min ?? 0}
        max={max ?? 100}
      />
    </div>
  );
};

export default Range;
