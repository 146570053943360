import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import { createClientToken, getToken } from "helpers/jwt";
import { RootStateInterface } from "reducer";
import { AuthStateInterface } from "../ducks";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import Checkbox from "components/inputs/Checkbox/Checkbox";
import FormField from "components/inputs/FormField/FormField";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";

import DroplaLogo from "assets/icons/dropla-software-logo.svg";
import PointDemonstration from "assets/point-demonstration.jpg";

import styles from "./auth-page.module.scss";

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector<RootStateInterface, AuthStateInterface>(
    (state) => state.auth
  );

  const [authorisedJWT, setAuthorisedJWT] = useState(getToken());

  const handleFormSubmit = async (values) => {
    const clientToken = await createClientToken({
      username: values.username,
      password: values.password,
      long: values.remember
    });

    dispatch(
      features.auth.actions.initTokenRequest({
        params: { clientToken },
        onSuccess: (res) => {
          setAuthorisedJWT(res.message);
        },
        onError: (res) => {
          toastr.error(`Error`, res.message);
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: { username: "", password: "", remember: false },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Login is required"),
      password: Yup.string().required("Password is required")
    }),
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (authorisedJWT) navigate("/");
  }, [authorisedJWT, navigate]);

  const handleRememberSwitch = () =>
    formik.setFieldValue("remember", !formik.values.remember);

  return (
    <div className={styles["auth-page"]}>
      {isLoading && (
        <div className={styles["loading"]}>
          <PreloaderWrapper loading={isLoading}></PreloaderWrapper>
        </div>
      )}
      <div className={styles["left"]}>
        <div className={styles["logo"]}>
          <img src={DroplaLogo} alt="logo" />
        </div>
        <div className={styles["description"]}>
          “Bringing together the best of all worlds. Dropla’s advanced sensor
          fusion platform integrates aerial, terrestrial and subsurface data to
          provide a comprehensive mapping of landmine presence, enabling
          precision detection and efficient clearing.” <br />
        </div>
        <div className={styles["signature"]}>Dropla Team</div>
        <div className={styles["demonstration-img"]}>
          <img src={PointDemonstration} alt="" />
        </div>
      </div>
      <div className={styles["right"]}>
        <div className={styles["title"]}>Sign in</div>
        <div className={styles["info"]}>
          Enter your login and password to enter to system
        </div>
        <FormikProvider value={formik}>
          <Form className={styles["form"]}>
            <FormField name={"username"} placeholder="name@example.com" />
            <FormField
              name={"password"}
              placeholder="password"
              type="password"
            />
            <Checkbox
              name={"remember"}
              label="Stay logged in for 30 days"
              checked={formik.values.remember}
              wrapperClassName={styles["checkbox"]}
              onClick={handleRememberSwitch}
            />
            <PrimaryButton type={"submit"} children={"Sign in"} />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default AuthPage;
