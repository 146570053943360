import cn from "classnames";

import EyeCloseIcon from "assets/icons/eye-close.svg";
import EyeOpenIcon from "assets/icons/eye-open.svg";

import styles from "./show-hide-button.module.scss";

interface ShowHideButtonInterface {
  title: string;
  onClick?: () => void;
  className?: string;
  isActive: boolean;
  isDisabled?: boolean;
  icon?: string;
}

const ShowHideButton = ({
  title,
  onClick,
  className,
  isActive,
  isDisabled,
  icon
}: ShowHideButtonInterface) => {
  return (
    <div
      className={cn(
        styles["show-hide-container"],
        { [styles["show-hide-container__disabled"]]: isDisabled },
        className
      )}
      onClick={onClick}
    >
      {icon && <img src={icon} alt={`icon-${title}`}  className={styles["show-hide__icon"]} />}
      <span className={styles["show-hide__title"]}>{title}</span>
      <span className={styles["show-hide__icon"]}>
        <img
          src={isActive ? EyeOpenIcon : EyeCloseIcon}
          alt={`button ${isActive ? "active" : "hidden"}`}
        />
      </span>
    </div>
  );
};

export default ShowHideButton;
