import { createSlice } from "@reduxjs/toolkit";

export interface MainStateInterface {
  isLoading: boolean;
  projects: ProjectInterface[];
  project: any;
  projectsTotal: number;
}
export interface ProjectInterface {
  name: string;
  created_at: string;
  id: number;
  layers: any[];
}

const initialState: MainStateInterface = {
  isLoading: false,
  projects: [],
  project: null,
  projectsTotal: 0
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    fetchProjectsRequest: (state) => {
      state.isLoading = true;
    },
    fetchProjectsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.projects = payload.projects ?? [];
      state.projectsTotal = payload.total;
    },
    fetchProjectsError: (state) => {
      state.isLoading = false;
    },

    createProjectRequest: (state) => {
      state.isLoading = true;
    },
    createProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.projects = [payload, ...state.projects];
    },
    createProjectError: (state) => {
      state.isLoading = false;
    },

    renameProjectRequest: (state) => {
      state.isLoading = true;
    },
    renameProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.projects = state.projects.map((project) =>
        project.id === payload.id ? payload : project
      );
    },
    renameProjectError: (state) => {
      state.isLoading = false;
    },

    deleteProjectRequest: (state) => {
      state.isLoading = true;
    },
    deleteProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.projects = state.projects.filter(
        (project) => project.id.toString() !== payload.message.toString()
      );
    },
    deleteProjectError: (state) => {
      state.isLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
