import {
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
  fork,
  cancel
} from "redux-saga/effects";

import { requestMiddleware, uploadFileMiddleware } from "helpers/api";
import {
  getMarkers,
  getMarkerDetails,
  getAries,
  getPolygons,
  removeMarker,
  addLayerLink,
  runInference,
  addLayerFile,
  getInferenceStatus,
  addMarker,
  putMarker,
  runSensorFusion
} from "services/api/map";
import { LayerAlias, MULTISPECTRAL_OPTIONS } from "constant";
import { getProject } from "services/api/project";
import { actions, InferenceStatus } from "./ducks";

const CHECK_STATUS_DELAY_MS = 5000;
const BACK_INFERENCE_DELAY_MS = 3000;

function* fetchProject({ payload }) {
  const request = getProject;

  const {
    fetchProjectSuccess: setSuccess,
    fetchProjectError: setError,
    setLayerIsActive,
    checkInferenceStatusRequest
  } = actions;

  function* onSuccess(res) {
    const defaultLayer = res.layers?.[0];
    if (defaultLayer) {
      const alias = defaultLayer.alias;
      const payload = { params: { alias } };

      yield put(
        yield setLayerIsActive({ ...payload, fields: { isActive: true } })
      );
    }

    yield put(yield checkInferenceStatusRequest(payload));
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* startSensorFusion({ payload }) {
  const request = runSensorFusion;

  const {
    startSensorFusionSuccess: setSuccess,
    startSensorFusionError: setError,
    setLayerIsActive
  } = actions;

  function* onSuccess() {
    yield delay(30000);
    yield put(setSuccess({ payload }));
    yield put(
      yield setLayerIsActive({ ...payload, fields: { isActive: true } })
    );
  }

  yield requestMiddleware({
    ...payload,
    request,
    // setSuccess,
    setError,
    onSuccess
  });
}

function* startInference({ payload }) {
  const request = runInference;

  const { startInferenceSuccess: setSuccess, startInferenceError: setError } =
    actions;

  const task = yield fork(delayInferenceStatusCheck, { payload });

  function* onError() {
    yield cancel(task);
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onError,
    isReturnRequestPayload: true
  });
}

function* delayInferenceStatusCheck({ payload }) {
  const { checkInferenceStatusRequest } = actions;

  yield delay(BACK_INFERENCE_DELAY_MS);
  yield put(yield checkInferenceStatusRequest(payload));
}

function* watchCheckInferenceStatus({ payload }) {
  const { clearState } = actions;

  const task = yield fork(checkInferenceStatus, { payload });

  yield takeLatest(clearState, function* () {
    yield cancel(task);
  });
}

function* checkInferenceStatus({ payload }) {
  const request = getInferenceStatus;
  const {
    checkInferenceStatusSuccess: setSuccess,
    checkInferenceStatusError: setError,
    checkInferenceStatusDone
  } = actions;

  let reqCount = 0;
  let isDone = false;

  function* onSuccess(res) {
    if (res.code) {
      const { code } = res;
      const isSuccess = InferenceStatus.SUCCESS === code;
      const isError = InferenceStatus.ERROR === code;
      isDone = isSuccess || isError;

      if (isDone) {
        yield put(
          yield checkInferenceStatusDone({
            showSuccessModal: isSuccess && reqCount > 1
          })
        );
      }
    }
  }

  const onError = () => {
    isDone = true;
  };

  while (!isDone) {
    reqCount = reqCount + 1;

    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess,
      onError
    });

    yield delay(CHECK_STATUS_DELAY_MS);
  }
}

// function* fetchLayers({ payload }) {
//   const request = getLayers;

//   const { fetchLayersSuccess: setSuccess, fetchLayersError: setError } =
//     actions;

//   function* onSuccess(res) {
//     const alias = res.find((item) => item.is_default).alias;
//     yield fetchMarkers({ payload: { params: { alias } } });
//   }

//   yield requestMiddleware({
//     ...payload,
//     request,
//     setSuccess,
//     setError,
//     onSuccess
//   });
// }

function* fetchAries({ payload }) {
  const request = getAries;

  const { fetchAriesSuccess: setSuccess, fetchAriesError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* handleSetLayerIsActive({ payload }) {
  const { id, markers, layers } = yield select((state) => state.map);
  const { alias } = payload.params;
  const { fetchMarkersRequest } = actions;

  if (alias === LayerAlias.MULTISPECTRAL) {
    const { setLayerParams } = actions;

    const layerData = layers.find((layer) => layer.alias === alias);

    const params = MULTISPECTRAL_OPTIONS(
      layerData?.rescale_min,
      layerData?.rescale_max
    )[0].params;

    yield put(yield setLayerParams({ params, alias }));
  }

  const isMarkersLoaded = markers.find((marker) => marker.layer === alias);

  if (!isMarkersLoaded)
    yield put(
      yield fetchMarkersRequest({
        ...payload,
        params: { ...payload.params, id }
      })
    );
}

function* fetchMarkers({ payload }) {
  const request = getMarkers;

  const { fetchMarkersSuccess: setSuccess, fetchMarkersError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* fetchPolygons({ payload }) {
  const request = getPolygons;

  const { fetchPolygonsSuccess: setSuccess, fetchPolygonsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchMarkerDetails({ payload }) {
  const request = getMarkerDetails;

  const {
    fetchMarkerDetailsSuccess: setSuccess,
    fetchMarkerDetailsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createLayer({ payload }) {
  const {
    createLayerSuccess: setSuccess,
    createLayerError: setError,
    setUploadLayerFileCancelEvent: getUploadCancelExecutor,
    setUploadLayerFileProgress: setUploadProgress,
    setLayerIsActive
  } = actions;

  function* onSuccess(res) {
    yield payload.onSuccess(res);
    yield put(
      yield setLayerIsActive({ ...payload, fields: { isActive: true } })
    );
  }

  if (payload.fields.file) {
    const request = addLayerFile;

    yield uploadFileMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess,
      setUploadProgress,
      getUploadCancelExecutor
    });
  } else {
    const request = addLayerLink;

    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess
    });
  }
}

function* createMarker({ payload }) {
  const request = addMarker;
  const { createMarkerSuccess: setSuccess, createMarkerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* updateMarker({ payload }) {
  const request = putMarker;
  const { updateMarkerSuccess: setSuccess, updateMarkerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* deleteMarker({ payload }) {
  const request = removeMarker;
  const { deleteMarkerSuccess: setSuccess, deleteMarkerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectRequest, fetchProject);
  yield takeLatest(actions.startInferenceRequest, startInference);
  yield takeLatest(actions.startSensorFusionRequest, startSensorFusion);
  yield takeLatest(actions.fetchAriesRequest, fetchAries);
  yield takeLatest(actions.fetchMarkersRequest, fetchMarkers);
  yield takeLatest(actions.createLayerRequest, createLayer);
  yield takeLatest(actions.fetchPolygonsRequest, fetchPolygons);
  yield takeLatest(actions.fetchMarkerDetailsRequest, fetchMarkerDetails);
  yield takeLatest(actions.createMarkerRequest, createMarker);
  yield takeLatest(actions.updateMarkerRequest, updateMarker);
  yield takeLatest(actions.deleteMarkerRequest, deleteMarker);
  yield takeLatest(actions.setLayerIsActive, handleSetLayerIsActive);
  yield takeEvery(
    actions.checkInferenceStatusRequest,
    watchCheckInferenceStatus
  );
}
