import { POINTS, PointTypes } from "constant";

import styles from "./map-point-icon.module.scss";

interface MapPointIconInterface {
  type: PointTypes;
}

const MapPointIcon = ({ type }: MapPointIconInterface) => {
  return (
    <div className={styles["marker"]} data-testid="marker-point">
      <div className={styles["plane"]}>
        <img src={POINTS[type]?.icon} alt={POINTS[type]?.label} />
      </div>
    </div>
  );
};
export default MapPointIcon;
