import { useMapEvents } from "react-leaflet";

const ClickToSetCoordinates = ({ setCoordinates }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setCoordinates({ lat, lng });
    }
  });

  return null;
};

export default ClickToSetCoordinates;
