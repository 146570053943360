import { Link } from "react-router-dom";
import styles from "./main-util.module.scss";

export interface MainUtilInterface {
  title: string;
  link: string;
  icon: string;
  target?: string;
}

const MainUtil = ({ title, link, icon, target }: MainUtilInterface) => {
  return (
    <Link
      to={link}
      target={target}
      rel="noopener noreferrer"
      className={styles["main-util__wrapper"]}
    >
      <div className={styles["main-util__icon"]}>
        <img src={icon} alt={`${title} logo`}></img>
      </div>
      <div className={styles["main-util__title"]}>{title}</div>
    </Link>
  );
};

export default MainUtil;
