import ZoomInIcon from "assets/icons/zoom-in.svg";
import ZoomOutIcon from "assets/icons/zoom-out.svg";

import IconButton from "components/buttons/IconButton/IconButton";

import styles from "./zoom-btns.module.scss";

interface ZoomBtnsInterface {
  zoomIn: () => void;
  zoomOut: () => void;
}

const ZoomBtns = ({ zoomIn, zoomOut }: ZoomBtnsInterface) => {
  return (
    <div className={styles["buttons"]}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          zoomIn();
        }}
        icon={ZoomInIcon}
        className={styles["icon-button"]}
      />
      <IconButton
        icon={ZoomOutIcon}
        onClick={(e) => {
          e.stopPropagation();
          zoomOut();
        }}
        className={styles["icon-button"]}
      />
    </div>
  );
};

export default ZoomBtns;
