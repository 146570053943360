import { env } from "./env";

import { MainUtilInterface } from "components/MainUtil/MainUtil";

import AiHelperPicture from "assets/ai-helper-picture.png";
import LabelingPicture from "assets/labeling-picture.png";
import OdmPicture from "assets/odm-picture.png";
import WikiPicture from "assets/wiki-picture.png";
import MineDetectionPicture from "assets/mine-detection-picture.png";

import RockIcon from "assets/icons/rock.svg";
import CrosshairIcon from "assets/icons/crosshair.svg";
import MineIcon from "assets/icons/mine.svg";
import TreeIcon from "assets/icons/tree-pine.svg";
import StumpIcon from "assets/icons/stump.svg";

export const AUTH_URL = env.REACT_APP_AUTH_URL;
export const GEO_VISION_API_URL = env.REACT_APP_GEO_VISION_API_URL;
export const ODM_API_URL = env.REACT_APP_ODM_API_URL;
export const EXTERNAL_API_URL = env.REACT_APP_EXTERNAL_API_URL;
export const PRIVATE_KEY = env.REACT_APP_PRIVATE_KEY;

//DELETE
export const AREA_COLORS = [
  {
    name: "Medium Hazard",
    color: "rgba(255, 122, 0, 0.8)",
    alias: "orange",
    isActive: false,
    radius: 3
  },
  {
    name: "Hard Hazard",
    color: "rgba(255, 0, 0, 0.8)",
    alias: "red",
    isActive: false,
    radius: 3
  }
];

const ODM_LINK = env.REACT_APP_ODM_LINK;
const CVAT_LINK = env.REACT_APP_CVAT_LINK;
const AI_HELPER_LINK = env.REACT_APP_AI_HELPER_LINK;
const WIKI_LINK = env.REACT_APP_WIKI_LINK;

export const UTILS_LIST: Array<MainUtilInterface> = [
  { title: "ODM", icon: OdmPicture, link: ODM_LINK, target: "_blank" },
  {
    title: "Labeling tool",
    icon: LabelingPicture,
    link: CVAT_LINK,
    target: "_blank"
  },
  {
    title: "AI Helper",
    icon: AiHelperPicture,
    link: AI_HELPER_LINK,
    target: "_blank"
  },
  {
    title: "Wiki mine",
    icon: WikiPicture,
    link: WIKI_LINK,
    target: "_blank"
  },
  {
    title: "Mine detection",
    icon: MineDetectionPicture,
    link: "/image-detection"
  }
  //TODO delete orthophoto-planning scenes
  // { title: "Map", icon: MapIcon, link: "/map" },
  // {
  //   title: "Orthophoto Planning",
  //   icon: CameraIcon,
  //   link: "/orthophoto-planning"
  // }
];

export const KYIV_COORDINATES = { lat: 50.450001, lng: 30.523333 };

export enum LayerAlias {
  RGB = "rgb",
  MULTISPECTRAL = "multispectral",
  THERMAL = "thermal",
  //TODO for  sensorFusion
  SENSOR_FUSION = "sensor-fusion"
  // ALL = "All"
}

export interface LayerInterface {
  name: string;
  color: string;
  alias: LayerAlias;
  isInit?: boolean;
}

export const LAYERS: Record<LayerAlias, LayerInterface> = {
  [LayerAlias.SENSOR_FUSION]: {
    name: "Sensor fusion",
    color: "#391A5F",
    alias: LayerAlias.SENSOR_FUSION
  },
  [LayerAlias.RGB]: {
    name: "RGB",
    color: "#FF0000",
    alias: LayerAlias.RGB
  },
  [LayerAlias.MULTISPECTRAL]: {
    name: "Multispectral",
    color: "#00CB50",
    alias: LayerAlias.MULTISPECTRAL
  },
  [LayerAlias.THERMAL]: {
    name: "Thermal",
    color: "#00D1FF",
    alias: LayerAlias.THERMAL
  }
};

export const DEFAULT_LAYERS_LIST: LayerInterface[] = Object.values(LAYERS);

export enum PointTypes {
  EXO = "exo",
  GCP = "gcp",
  TREE = "tree",
  STUMP = "stump",
  ROCK = "rock"
}

export interface PointTypeInterface {
  value: string;
  label: string;
  icon: string;
}

export const POINTS: Record<PointTypes, PointTypeInterface> = {
  [PointTypes.EXO]: {
    value: PointTypes.EXO,
    label: "EXO (Explosive object)",
    icon: MineIcon
  },
  [PointTypes.GCP]: {
    value: PointTypes.GCP,
    label: "GCP (Ground Control Point)",
    icon: CrosshairIcon
  },
  [PointTypes.TREE]: {
    value: PointTypes.TREE,
    label: "Tree",
    icon: TreeIcon
  },
  [PointTypes.STUMP]: {
    value: PointTypes.STUMP,
    label: "Stump",
    icon: StumpIcon
  },
  [PointTypes.ROCK]: {
    value: PointTypes.ROCK,
    label: "Rock",
    icon: RockIcon
  }
};

export const DEFAULT_POINTS_LIST: PointTypeInterface[] = Object.values(POINTS);

export const MULTISPECTRAL_OPTIONS = (min, max) => {
  //TODO remove minRescale and maxRescale. Default values for old projects
  const minRescale = min === 0 ? -0.0009298980003222823 : min;
  const maxRescale = max === 0 ? 0.05864158272743225 : max;
  return [
    {
      name: "RGB",
      params: `?rescale=${minRescale}%2C${maxRescale}`
    },
    {
      name: "NDVI",
      params:
        "?color_map=viridis&formula=NDVI&bands=auto&hillshade=&rescale=-0.551,0.26"
    },
    {
      name: "vNDVI",
      params:
        "?color_map=spectral_r&formula=vNDVI&bands=auto&hillshade=&rescale=0.56,0.79"
    },
    {
      name: "EXG",
      params:
        "?color_map=cividis&formula=EXG&bands=auto&hillshade=&rescale=-0.009427607990801334,0.019022520631551743"
    }
  ];
};

export const dmsRegex =
  /(\d{1,3})[°d]\s*(\d{1,2})['m]\s*(\d{1,2}(?:\.\d+)?)["s]?\s*([NSEW]?)/i;

export const ddRegex = /^-?\d+(\.\d+)?$/;
