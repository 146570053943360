import cn from "classnames";

import { ErrorMessage, Field } from "formik";
import React, { ChangeEvent, FocusEvent } from "react";

import styles from "./form-field.module.scss";

interface FormFieldInterface {
  id?: any;
  name: string;
  value?: any;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  maskChar?: string;
  maskFormatChars?: Object;
  placeholder?: string;
  errClassName?: string;
  errComponent?: React.ComponentType;
  type?: React.HTMLInputTypeAttribute;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onInput?: (e: FocusEvent<HTMLInputElement>) => void;
  widthByValue?: boolean;
  maxWidth?: string;
  minWidth?: string;
  textAlign?: string;
  as?: any;
  label?: string;
}
const FormField = ({ label, ...props }: FormFieldInterface) => {
  return (
    <div className={cn(styles["form-field-wrapper"], props.className)}>
      {label && (
        <label htmlFor={props.id || props.name} aria-disabled={props.disabled}>
          {label}
        </label>
      )}
      <Field {...props} className={styles["form-field__input"]} />
      <ErrorMessage
        component="div"
        className={styles["error"]}
        name={props.name}
      />
    </div>
  );
};

export default FormField;
