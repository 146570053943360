import cn from "classnames";
import styles from "./map-project-name.module.scss";

interface MapProjectNameInterface {
  name: string;
  className?: string;
}

const MapProjectName = ({ name, className }: MapProjectNameInterface) => {
  return <div className={cn(styles["project-name"], className)}>{name}</div>;
};

export default MapProjectName;
