import styles from "./progress-bar.module.scss";

interface ProgressBarInterface {
  value: number;
  max: number;
}

const ProgressBar = ({ value, max }: ProgressBarInterface): JSX.Element => {
  const progress = (value / max) * 100;

  const progressBarStyle = {
    width: `${progress}%`
  };

  return (
    <div className={styles["progress-bar-wrapper"]}>
      <div style={progressBarStyle} className={styles["progress-bar"]} />
    </div>
  );
};

export default ProgressBar;
