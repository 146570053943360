import AlertIcon from "assets/icons/alert-circle.svg";

import styles from "./alert.module.scss";

interface AlertInterface {
  title: string;
  description: string;
}

const Alert = ({ title, description }: AlertInterface) => {
  return (
    <div className={styles["alert"]}>
      <img src={AlertIcon} alt="alert" />
      <div className={styles["alert__text-container"]}>
        <h2 className={styles["alert__title"]}>{title}</h2>
        <h4 className={styles["alert__description"]}>{description}</h4>
      </div>
    </div>
  );
};

export default Alert;
