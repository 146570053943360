export const replaceNullWithUndefined = (obj: any) => {
  for (let key in obj) {
    if (obj[key] === null) {
      obj[key] = undefined;
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      replaceNullWithUndefined(obj[key]);
    }
  }
  return obj;
};
