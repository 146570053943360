import { replaceNullWithUndefined } from "helpers/formatObject";

import { createSlice } from "@reduxjs/toolkit";

export interface ProjectReportInterface {
  project_name: string;
  scan_date: string;
  scan_time: string;
  area_bounds: Array<number>;
  area: number;
  rgb_cameras: Array<string>;
  multispectral_cameras: Array<string>;
  points: any;
}

export interface ProjectReportStateInterface {
  isLoading: boolean;
  projectReport?: ProjectReportInterface;
}

const initialState: ProjectReportStateInterface = {
  isLoading: false,
  projectReport: null
};

export const mainSlice = createSlice({
  name: "projectReport",
  initialState,
  reducers: {
    fetchProjectReportRequest: (state) => {
      state.isLoading = true;
    },
    fetchProjectReportSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.projectReport = replaceNullWithUndefined(payload);
    },
    fetchProjectReportError: (state) => {
      state.isLoading = false;
    },

    fetchProjectReportToPDFRequest: (state) => {
      state.isLoading = true;
    },
    fetchProjectReportToPDFSuccess: (state) => {
      state.isLoading = false;
    },
    fetchProjectReportToPDFError: (state) => {
      state.isLoading = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
