import { ExternalProvider, ODMProvider } from "../ApiServiceProviders";

export const getOrthophotoOptions = () => {
  return ExternalProvider.client
    .get(`/options`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const createOrthophotoTask = ({ params }) => {
  return ODMProvider.clientUpload
    .post(`/inference/back/odm/orthophotoplan/create`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getOrthophotoTaskInfo = ({ params }) => {
  return ODMProvider.clientUpload
    .get(`/inference/back/odm/orthophotoplan/${params.uuid}/info`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const uploadOrthophoto = ({ params }) => {
  return ODMProvider.clientUpload
    .post(`/inference/back/ai/upload/geojson`, params)
    .then((response) => response)
    .catch((error) => error.response);
};

export const uploadImageInference = ({ fields, params, config }) => {
  return ODMProvider.clientUpload
    .post(`/inference/back/ai/image/inference/${params.alias}`, fields, config)
    .then((response) => response)
    .catch((error) => error.response);
};
