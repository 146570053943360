import { MutableRefObject, useEffect, useRef } from "react";

const useOutsideClick = (
  callback: (...args: any[]) => void,
  isActive: boolean
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    if (isActive) document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback, isActive]);

  return ref;
};

export default useOutsideClick;
