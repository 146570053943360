import cn from "classnames";
import { ErrorMessage } from "formik";
import Select, { components, Props, StylesConfig } from "react-select";

import ChevronIcon from "assets/icons/chevron-down.svg";

import styles from "./form-select.module.scss";

interface IOption {
  value: string;
  label: string;
}

interface SelectInterface extends Props {
  value: IOption;
  name?: string;
  label?: string;
  className?: string;
  withFormik?: boolean;
  isSearchable?: boolean;
  onSelectChange?: (item: any, name: string) => void;
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ChevronIcon} alt="chevron-down" />
    </components.DropdownIndicator>
  );
};

const FormSelect = ({
  label,
  withFormik,
  name,
  value,
  className,
  isSearchable,
  onSelectChange,
  ...props
}: SelectInterface) => {
  const selectStyles: StylesConfig = {
    menu: (styles) => ({
      ...styles,
      fontSize: "14px",
      padding: "8px 0px",
      boxShadow:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)"
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: "inherit",
      color: "#000000",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#f4f4f5"
      }
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? "#71717A" : "#000000"
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "14px"
    }),
    container: (styles) => ({
      ...styles,
      width: "100%"
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: "1px solid #e4e4e7",
      outline: "0",
      borderRadius: "6px",
      margin: "0",
      boxShadow: "none",
      width: "100%",
      minHeight: "42.5px",
      height: "42.5px",
      cursor: "pointer",
      opacity: 1,
      "&:hover": {
        borderColor: "#d6d6d6"
      }
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: "27px",
      height: "27px"
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: "#18181B",
      position: "relative",
      top: "7px",
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
      transition: "0.4s"
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none"
    })
  };

  const handleChange = (item: any) => {
    onSelectChange && onSelectChange(item, name);
  };
  return (
    <div className={cn(styles["form-field-wrapper"], className)}>
      {label && <label aria-disabled={props.isDisabled}>{label}</label>}
      <Select
        {...props}
        value={value}
        className={cn("select-container", className)}
        styles={selectStyles}
        classNamePrefix="select"
        name={name}
        onChange={handleChange}
        isSearchable={isSearchable}
        noOptionsMessage={() => "No options"}
        placeholder="Select"
        components={{ DropdownIndicator }}
      />
      {withFormik && (
        <ErrorMessage name={name} component="div" className={styles["error"]} />
      )}
    </div>
  );
};

export default FormSelect;
