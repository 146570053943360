import { uploadImageInference } from "services/api/odm";

import { actions } from "./ducks";
import { uploadFileMiddleware } from "helpers/api";
import { takeLatest } from "redux-saga/effects";

function* fetchUploadImageInference({ payload }) {
  const request = uploadImageInference;

  const {
    fetchUploadImageInferenceSuccess: setSuccess,
    fetchUploadImageInferenceError: setError,
    fetchUploadImageInferenceProgress: setUploadProgress
  } = actions;

  yield uploadFileMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    setUploadProgress,
    isReturnRequestPayload: true
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchUploadImageInferenceRequest,
    fetchUploadImageInference
  );
}
