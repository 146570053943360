import cn from "classnames";

import styles from "./map-sensor-fusion-marker-icon.module.scss";

interface SensorFusionProbabilityInterface {
  probability_rgb?: number;
  probability_multispectral?: number;
  probability_thermal?: number;
}

interface MapSensorFusionMarkerIconInterface {
  color: string;
  sensorFusion: SensorFusionProbabilityInterface;
  probability: number;
  isActive?: boolean;
  markerName: string;
}

const MapSensorFusionMarkerIcon = ({
  color,
  isActive,
  sensorFusion,
  probability,
  markerName
}: MapSensorFusionMarkerIconInterface) => {
  return (
    <div
      className={cn(styles["marker"], {
        [styles["marker--active"]]: isActive
      })}
      data-testid="marker"
    >
      {markerName && (
        <span
          style={{ backgroundColor: color }}
          className={cn(styles["marker__text"], styles["marker__mine-name"])}
        >
          {markerName}
        </span>
      )}
      <span
        style={{ backgroundColor: color }}
        className={cn(styles["marker__text"], styles["marker__probability"])}
      >
        {Math.round(probability * 100)}%
      </span>
      <span
        style={{ backgroundColor: color }}
        className={cn(styles["marker__text"], styles["marker__probabilities"])}
      >
        {sensorFusion.probability_rgb && (
          <div className={styles["probability_rgb"]}>
            {Math.round(sensorFusion.probability_rgb * 100)}%
          </div>
        )}
        {sensorFusion.probability_multispectral && (
          <div className={styles["probability_multispectral"]}>
            {Math.round(sensorFusion.probability_multispectral * 100)}%
          </div>
        )}
        {sensorFusion.probability_thermal && (
          <div className={styles["probability_thermal"]}>
            {Math.round(sensorFusion.probability_thermal * 100)}%
          </div>
        )}
      </span>
      <div className={styles["pin"]} style={{ backgroundColor: color }} />
    </div>
  );
};
export default MapSensorFusionMarkerIcon;
