import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const ImageDetection = (props: any): JSX.Element => {
  const backPath = "/";

  return (
    <Layout title="Image detection" isFullFill backPath={backPath}>
      <features.imageDetection.page {...props} />
    </Layout>
  );
};

export default ImageDetection;
