import {
  createOrthophotoTask,
  getOrthophotoOptions,
  getOrthophotoTaskInfo,
  uploadOrthophoto
} from "services/api/odm";

import { actions } from "./ducks";
import { requestMiddleware } from "helpers/api";
import { takeLatest } from "redux-saga/effects";

function* fetchOrthophotoOptions({ payload }) {
  const request = getOrthophotoOptions;

  const {
    fetchOrthophotoOptionsSuccess: setSuccess,
    fetchOrthophotoOptionsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchCreateOrthophotoTask({ payload }) {
  const request = createOrthophotoTask;

  const {
    fetchCreateOrthophotoTaskSuccess: setSuccess,
    fetchCreateOrthophotoTaskError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchOrthophotoTaskInfo({ payload }) {
  const request = getOrthophotoTaskInfo;

  const {
    fetchOrthophotoTaskInfoSuccess: setSuccess,
    fetchOrthophotoTaskInfoError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchUploadOrthophoto({ payload }) {
  const request = uploadOrthophoto;

  const {
    fetchUploadOrthophotoSuccess: setSuccess,
    fetchUploadOrthophotoError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(
    actions.fetchOrthophotoOptionsRequest,
    fetchOrthophotoOptions
  );
  yield takeLatest(
    actions.fetchCreateOrthophotoTaskRequest,
    fetchCreateOrthophotoTask
  );
  yield takeLatest(
    actions.fetchOrthophotoTaskInfoRequest,
    fetchOrthophotoTaskInfo
  );
  yield takeLatest(actions.fetchUploadOrthophotoRequest, fetchUploadOrthophoto);
}
