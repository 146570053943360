import { takeLatest } from "redux-saga/effects";
import { actions } from "./ducks";

import { auth } from "services/api/auth";
import { decodeJwt, deleteAuthData, setToken } from "helpers/jwt";
import { requestMiddleware } from "helpers/api";
import { createBrowserHistory } from "history";
import { updateProvidersToken } from "services/ApiServiceProviders";

const history = createBrowserHistory({ window });

function* initToken({ payload }) {
  const request = auth;
  const { initTokenSuccess: setSuccess, initTokenError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess: (res) => {
      payload.onSuccess(res);

      const token = res.message;

      const decodedToken = decodeJwt(token);

      setToken(token, decodedToken.exp);
      updateProvidersToken(token);
    }
  });
}

function signOut() {
  deleteAuthData();
  history.push("/auth");
  window.location.reload();
}

export default function* watchSaga() {
  yield takeLatest(actions.initTokenRequest, initToken);
  yield takeLatest(actions.signOutRequest, signOut);
}
