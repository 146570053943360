import { ddRegex, dmsRegex } from "constant";

export const convertDMSToDD = (coords) => {
  const dmsToDD = (dmsStr, isLatitude = true) => {
    const match = dmsStr.match(dmsRegex);

    if (!match) return null;

    const degrees = parseFloat(match[1]);
    const minutes = parseFloat(match[2]);
    const seconds = parseFloat(match[3]);
    let direction = match[4].toUpperCase();

    let decimalDegrees = degrees + minutes / 60 + seconds / 3600;

    if (!direction) {
      direction = isLatitude ? "N" : "E";
    }

    if (direction === "S" || direction === "W") {
      decimalDegrees = -decimalDegrees;
    }

    return decimalDegrees;
  };

  const convertCoordinate = (coordStr, isLatitude) => {
    if (ddRegex.test(coordStr)) {
      return parseFloat(coordStr);
    }
    return dmsToDD(coordStr, isLatitude);
  };

  const lat = convertCoordinate(coords.lat.toString().trim(), true);
  const lng = convertCoordinate(coords.lng.toString().trim(), false);

  return { lat, lng };
};
