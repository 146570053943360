import IconButton from "components/buttons/IconButton/IconButton";

import PrevIcon from "assets/icons/caret-left.svg";
import NextIcon from "assets/icons/caret-right.svg";
import FirstIcon from "assets/icons/double-arrow-left.svg";
import LastIcon from "assets/icons/double-arrow-right.svg";

import styles from "./pagination.module.scss";

interface PaginationInterface {
  currentPage: number;
  pageCount: number;
  onFirstPage: () => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  onLastPage: () => void;
}

const Pagination = ({
  currentPage,
  pageCount,
  onFirstPage,
  onPrevPage,
  onNextPage,
  onLastPage
}: PaginationInterface) => {
  return (
    <div className={styles["pagination"]}>
      <label className={styles["pagination__stats"]}>
        Page {currentPage + 1} of {pageCount}
      </label>
      <div className={styles["pagination__btns"]}>
        <IconButton onClick={onFirstPage} icon={FirstIcon} />
        <IconButton onClick={onPrevPage} icon={PrevIcon} />
        <IconButton onClick={onNextPage} icon={NextIcon} />
        <IconButton onClick={onLastPage} icon={LastIcon} />
      </div>
    </div>
  );
};

export default Pagination;
