import cn from "classnames";

import styles from "./primary-button.module.scss";

interface PrimaryButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: string;
  onClick?: () => void;
  icon?: string;
  textSize?: number;
  className?: string;
  isDisable?: boolean;
}

const PrimaryButton = ({
  type,
  children,
  onClick,
  icon,
  textSize,
  className,
  isDisable
}: PrimaryButtonInterface) => {
  return (
    <button
      type={type}
      className={cn(styles["primary"], className, {
        [styles["primary--disable"]]: isDisable
      })}
      style={{ fontSize: textSize }}
      onClick={onClick}
      disabled={isDisable}
    >
      {icon && (
        <div className={styles["primary__icon"]}>
          <img src={icon} alt={children + "-icon"} />
        </div>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
