import styles from "./secondary-button.module.scss";

interface LightButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: string;
  onClick?: () => void;
  textSize?: number;
  icon?: string;
}

const LightButton = ({
  type,
  children,
  textSize,
  onClick,
  icon
}: LightButtonInterface) => {
  return (
    <button
      type={type ?? "button"}
      className={styles["secondary"]}
      onClick={onClick}
      style={{ fontSize: textSize }}
    >
      {icon && (
        <div className={styles["secondary__icon"]}>
          <img src={icon} alt={children + "-icon"} />
        </div>
      )}
      {children}
    </button>
  );
};

export default LightButton;
