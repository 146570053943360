import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootStateInterface } from "reducer";

import features from "features";
import { ProjectReportStateInterface } from "../ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Report from "../components/Report/Report";

import PrintIcon from "assets/icons/print.svg";
import SaveIcon from "assets/icons/save.svg";
import SendIcon from "assets/icons/send.svg";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";

import styles from "./project-report-page.module.scss";

const PAGE_MARGIN = 5;
const PDF_GENERATOR_CONFIG = {
  model: {},
  options: {
    excludeBuiltinStyles: false,
    landscape: false,
    margins: {
      bottom: PAGE_MARGIN,
      left: PAGE_MARGIN,
      right: PAGE_MARGIN,
      top: PAGE_MARGIN
    },
    pageFormat: "A4",
    pageSize: {
      width: 210,
      height: 297
    }
  },
  templateEngine: "golang"
};
const FORM_RESET_PADDINGS = "<style>.pdf{padding:0px;}</style>";

const ProjectReportPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef(null);

  const { projectReport, isLoading } = useSelector<
    RootStateInterface,
    ProjectReportStateInterface
  >((state) => state.projectReport);

  const downloadFile = useCallback(
    (res: { url: string }) => {
      const link = document.createElement("a");
      link.href = res.url;
      link.setAttribute("download", `report-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    [id]
  );

  const printReport = useCallback(() => {
    const formElement = formRef.current;
    const printContents = formElement.outerHTML;
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(printContents);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }, []);

  const renderPdf = useCallback(() => {
    const formElement = formRef.current;
    const formHTML = `${formElement.outerHTML}${FORM_RESET_PADDINGS}`;

    dispatch(
      features.projectReport.actions.fetchProjectReportToPDFRequest({
        fields: {
          ...PDF_GENERATOR_CONFIG,
          htmlTemplate: formHTML
        },
        onSuccess: downloadFile
      })
    );
  }, [dispatch, downloadFile]);

  useEffect(() => {
    dispatch(
      features.projectReport.actions.fetchProjectReportRequest({
        params: { id }
      })
    );
  }, [dispatch, id]);

  return (
    <div>
      <div className={styles["report-actions"]}>
        <CommonButton onClick={printReport} icon={PrintIcon}>
          Print
        </CommonButton>
        <CommonButton disabled icon={SendIcon}>
          Send
        </CommonButton>
        <div className={styles["report-actions-spacer"]} />
        <CommonButton disabled>Cancel</CommonButton>
        <PrimaryButton onClick={renderPdf} icon={SaveIcon}>
          Test
        </PrimaryButton>
      </div>

      <PreloaderWrapper loading={isLoading}>
        {projectReport && <Report ref={formRef} {...projectReport} />}
      </PreloaderWrapper>
    </div>
  );
};

export default ProjectReportPage;
