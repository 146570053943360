import { Field } from "formik";
import { useCallback, useEffect, useRef } from "react";

interface FormTextareaInterface {
  name: string;
  label?: string;
  className?: string;
}

const FormTextarea = ({ name, label, className }: FormTextareaInterface) => {
  const textareaRef = useRef(null);

  const recalculateHeight = useCallback(() => {
    const element = textareaRef.current;
    if (element) {
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;
    }
  }, []);

  const onChange = useCallback(
    (field) => (e) => {
      const target = e.target;
      recalculateHeight();
      field.onChange("message", target.value);
    },
    [recalculateHeight]
  );

  useEffect(() => {
    recalculateHeight();
  }, [recalculateHeight]);

  return (
    <Field name={name}>
      {({ field }) => (
        <div className={className}>
          {label?.length > 0 && <label>{label}</label>}
          <textarea
            {...field}
            rows="1"
            ref={textareaRef}
            style={{ resize: "none", overflow: "hidden" }}
            onInput={onChange(field)}
          />
        </div>
      )}
    </Field>
  );
};

export default FormTextarea;
