import MainUtil from "components/MainUtil/MainUtil";

import { UTILS_LIST } from "constant";

import styles from "./utils-list.module.scss";

const UtilsList = () => {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__head"]}>
        <div className={styles["title"]}>Tools</div>
      </div>
      <div className={styles["wrapper__body"]}>
        <div className={styles["utils-list"]}>
          {UTILS_LIST.map((item, index) => (
            <MainUtil {...item} key={`util-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default UtilsList;
