import { useCallback } from "react";
import { useDispatch } from "react-redux";

import LogOutIcon from "assets/icons/exit.svg";

import features from "features";
import styles from "./log-out-button.module.scss";

const LogOutButton = () => {
  const dispatch = useDispatch();
  const onLogOut = useCallback(() => {
    dispatch(features.auth.actions.signOutRequest());
  }, [dispatch]);
  
  return (
    <div className={styles["log-out"]} onClick={onLogOut}>
      <div className={styles["log-out__icon"]}>
        <img src={LogOutIcon} alt="log-out-icon" />
      </div>
      Log out
    </div>
  );
};

export default LogOutButton;
