import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import LightButton from "components/buttons/LightButton/LightButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import features from "features";
import { RootStateInterface } from "reducer";

import styles from "./delete-modal.module.scss";

interface DeleteModalInterface {
  title?: string;
  subtitle?: string;
  isDisableClose?: boolean;
  onAcceptClick?: () => void;
  onDeclineClick?: () => void;
}

const DeleteModal = () => {
  const dispatch = useDispatch();

  const { title, isDisableClose, subtitle, onAcceptClick, onDeclineClick } =
    useSelector<RootStateInterface, DeleteModalInterface>(
      (state) => state.modal.modalProps
    );

  const onDecline = useCallback(() => {
    dispatch(features.modal.actions.hideModal());
  }, [dispatch]);

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
    >
      <div className={styles["delete-modal"]}>
        <div className={styles["delete-modal__text-container"]}>
          <h2 className={styles["delete-modal__title"]}>{title}</h2>
          <h4 className={styles["delete-modal__subtitle"]}>{subtitle}</h4>
        </div>
        <div className={styles["delete-modal__controls-wrapper"]}>
          <LightButton textSize={14} onClick={onDeclineClick ?? onDecline}>
            Cancel
          </LightButton>
          <PrimaryButton type="button" textSize={14} onClick={onAcceptClick}>
            Delete
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default DeleteModal;
