import { takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import { generatePDF, getProjectReport } from "services/api/project";
import { actions } from "./ducks";

function* fetchProjectReport({ payload }) {
  const request = getProjectReport;

  const {
    fetchProjectReportSuccess: setSuccess,
    fetchProjectReportError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchProjectReportToPDF({ payload }) {
  const request = generatePDF;

  const {
    fetchProjectReportToPDFSuccess: setSuccess,
    fetchProjectReportToPDFError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectReportRequest, fetchProjectReport);
  yield takeLatest(
    actions.fetchProjectReportToPDFRequest,
    fetchProjectReportToPDF
  );
}
