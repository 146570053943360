import { Form, FormikProvider, useFormik } from "formik";
import React, { forwardRef, useMemo } from "react";

import { DEFAULT_LAYERS_LIST, LayerAlias } from "constant";
import { customToFixed, squareMetersToHectares } from "helpers/numbers";
import { ProjectReportInterface } from "scenes/ProjectReportPage/ducks";

import FormTextarea from "components/FormTextarea/FormTextarea";
import FormField from "components/inputs/FormField/FormField";
import Table, { HeadItemInterface } from "components/Table/Table";
import LogoForPDF from "../LogoForPDF";

import styles from "./styles";

const Report = forwardRef<HTMLDivElement>(
  (defaultData: ProjectReportInterface, ref) => {
    //TODO maybe for future
    const handleSubmit = () => {};

    const arrayToStringColumn = (array: any[], isHTMLFormat?: boolean) => {
      if (!Array.isArray(array)) {
        console.error("arrayToStringColumn: IS NOT ARRAY", array);

        return;
      }

      if (isHTMLFormat)
        return array.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        ));
      return array.join("\n");
    };

    const formik = useFormik({
      validateOnChange: true,
      initialValues: {
        ...defaultData,
        area_bounds: arrayToStringColumn(defaultData.area_bounds),
        area: `${customToFixed(
          squareMetersToHectares(defaultData.area),
          2
        )} ha`,
        summery: ""
      },
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: handleSubmit
    });

    const tableHeaders: HeadItemInterface[] = useMemo(() => {
      const layersRows = DEFAULT_LAYERS_LIST.map((layer) => {
        return {
          title: layer.name,
          alias: layer.alias,
          mutator: (val) => {
            const roundedNumber = (val * 100).toFixed(0);
            return `${roundedNumber}%`;
          },
          thClass:
            layer.alias === LayerAlias.RGB ? "" : "only-first-letter-mobile"
        };
      });

      return [
        { title: "№", mutator: "increment" },
        { title: "Name UxO", alias: "name" },
        {
          title: "Authenticity",
          alias: "authenticity",
          thClass: "only-first-letter-mobile"
        },
        ...layersRows,
        {
          title: "Coordinates",
          alias: "coordinates",
          mutator: (val) => arrayToStringColumn(val, true),
          tdClass: "text-left"
        },
        { title: "Comments", alias: "Comments" },
        {
          title: "Photo",
          alias: "photo",
          element: (val, i) => (
            <img src={val} width="120px" height="120px" alt={`photo-${i}`} />
          )
        }
      ];
    }, []);

    return (
      <FormikProvider value={formik}>
        <div className="pdf" ref={ref}>
          <style>{styles}</style>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
            rel="stylesheet"
          />
          <div className="pdf__header">
            <div className="header__info">
              Dropla Tech ApS
              <br /> CVR number: 44198282
            </div>
            <div>
              <LogoForPDF />
            </div>
          </div>
          <div className="pdf__title">
            Report on Scanning
            <br /> of a Mined Area
          </div>
          <div className="pdf__reporter">
            Reported by:<span>Dropla tech ApS</span>
          </div>
          <div className="pdf__area-of-interest">
            {defaultData.project_name}
          </div>
          <Form>
            <section className="section">
              <div className="section__title">Technical conditions</div>
              <FormField
                className="section__input"
                name="scan_date"
                label="Scan date"
              />
              <FormField
                className="section__input"
                name="scan_time"
                label="Scanning time"
              />
              <FormField
                className="section__input"
                name="reporter"
                label="Reporter"
              />
              <FormTextarea
                className="section__input"
                name="scanningEquipment"
                label="Scanning Equipment"
              />
              <FormTextarea
                className="section__input"
                name="rgb_cameras"
                label="RGB camera"
              />
              <FormTextarea
                className="section__input"
                name="multispectral_cameras"
                label="Multispectral camera"
              />
              <FormField
                className="section__input"
                name="GNSS/RTK"
                label="GNSS/RTK"
              />
              <FormField
                className="section__input"
                name="thermalCamera"
                label="Thermal camera"
              />
            </section>
            <section className="section">
              <div className="section__title">Description of the Area</div>
              <FormTextarea
                className="section__input"
                name="area_bounds"
                label="Coordinates of the Area of Interest Corners"
              />
              <FormField
                className="section__input"
                name="area"
                label="Size Area"
              />
              <FormField
                className="section__input"
                name="Features of the area"
                label="Features of the area"
              />
              <FormField
                className="section__input"
                name="Weather Conditions "
                label="Weather Conditions "
              />
            </section>
            {defaultData.points && (
              <section className="section">
                <div className="section__title">
                  Table of Found Explosive Ordnance and Undefined Anomalies
                </div>
                <Table
                  className="section__table"
                  headers={tableHeaders}
                  body={defaultData.points}
                />
              </section>
            )}

            <section className="section">
              <div className="section__title">Summery</div>
              <FormTextarea className="section__input" name="Summery" />
            </section>
            {/* <PrimaryButton type={"submit"} /> */}
          </Form>
        </div>
      </FormikProvider>
    );
  }
);

export default Report;
