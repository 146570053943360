import { Options, Splide, SplideSlide } from "@splidejs/react-splide";

import styles from "./slider.module.scss";

interface SliderInterface {
  image: string;
}

const Slider = ({ image }: SliderInterface) => {
  const options: Options = {
    arrows: false,
    classes: {
      pagination: `splide__pagination ${styles["slider__pagination"]}`,
      page: `splide__pagination__page ${styles["slider__page"]}`
    }
  };

  return (
    <Splide options={options} aria-label="RGB">
      <SplideSlide>
        <img src={image} alt="mine" className={styles["slider__image"]} />
      </SplideSlide>
    </Splide>
  );
};

export default Slider;
