import { MapProvider, ODMProvider } from "../ApiServiceProviders";
import { getProject } from "./project";

export const getLayers = () => {
  return MapProvider.client
    .get(`/layers`)
    .then((response) => response)
    .catch((error) => error.response);
};
export const getAries = () => {
  return {
    status: 200,
    data: [
      {
        title: "All",
        alias: "All",
        color: "#000000",
        // icon: string,
        isActive: true
      },
      {
        title: "Orange",
        alias: "Orange",
        color: "#FF7A00"
        // icon: string,
        // isActive: boolean,
      },
      {
        title: "Red",
        alias: "Red",
        color: "#FF0000"
        // icon: string,
        // isActive: boolean,
      }
    ]
  };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};

export const getMarkers = ({ params }) => {
  return MapProvider.client
    .get(`/projects/${params.id}/layers/${params.alias}/points?limit=99999999`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getPolygons = () => {
  return {
    status: 200,
    data: [
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.091],
        id: "1",
        text: "1%"
      },
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.092],
        id: "2",
        text: "2%"
      },
      {
        alias: "All",
        icon: "string",
        color: "#FF0000",
        coordinates: [51.505, -0.093],
        id: "3",
        text: "3%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.094],
        id: "4",
        text: "4%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.095],
        id: "5",
        text: "5%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.096],
        id: "6",
        text: "6%"
      },
      {
        alias: "Red",
        icon: "string",
        color: "#000000",
        coordinates: [51.505, -0.097],
        id: "7",
        text: "7%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.098],
        id: "8",
        text: "8%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.099],
        id: "9",
        text: "9%"
      },
      {
        alias: "Orange",
        color: "#FF7A00",
        icon: "string",
        coordinates: [51.505, -0.16],
        id: "16",
        text: "16%"
      }
    ]
  };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};

export const getMarkerDetails = async ({ params }) => {
  return MapProvider.client
    .get(`/projects/${params.projectId}/points/${params.id}`)
    .then((response) => response)
    .catch((error) => error.response);

  // console.log(
  //   await MapProviders.client
  //     .get(`http://165.22.75.123:1921/mines`)
  //     .then((response) => response)
  //     .catch((error) => error.response)
  // );

  // await new Promise<void>((res) => setTimeout(() => res(), 1000));
  // return {
  //   status: 200,
  //   data: params
  // };
  // return ApiProvider()
  //   //TODO update path to layers
  //   .client.get(`/layers`)
  //   .then((response) => response)
  //   .catch((error) => error.response);
};

export const addMarker = async ({ params, fields }) => {
  return MapProvider.client
    .post(`/projects/${params.projectId}/layers/rgb/points`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};
export const putMarker = async ({ params, fields }) => {
  return MapProvider.client
    .put(`/projects/${params.projectId}/points/${params.markerId}`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const removeMarker = async ({ params }) => {
  return MapProvider.client
    .delete(`/projects/${params.projectId}/points/${params.markerId}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const addLayerLink = ({ params, fields }) => {
  return MapProvider.client
    .post(`/projects/${params.id}/layers/${params.alias}`, fields)
    .then((response) => {
      //TODO remove after back fix response
      return getProject({ params }).then((response2) => {
        const customRes = {
          ...response,
          data: response2.data.layers.find(
            (item) => item.alias === params.alias
          )
        };
        return customRes;
      });
    })
    .catch((error) => error.response);
};
export const addLayerFile = ({ params, fields, config }) => {
  return MapProvider.clientUpload
    .post(`/projects/${params.id}/layers/${params.alias}`, fields, config)
    .then((response) => response)
    .catch((error) => error.response);
};

export const runSensorFusion = ({ params, fields }) => {
  return ODMProvider.client
    .post(
      `https://new-inference.vision.dropla.tech/projects/${params.id}/inference/sensor-fusion`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};
export const runInference = ({ params, fields }) => {
  return ODMProvider.client
    .post(
      `/inference/back/ai/projects/${params.id}/${params.alias}/inference`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const getInferenceStatus = ({ params }) => {
  return ODMProvider.client
    .get(`/inference/back/ai/projects/${params.id}/inference/status`)
    .then((response) => response)
    .catch((error) => error.response);
};
