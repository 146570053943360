import classNames from "classnames";

import styles from "./detection-type-button.module.scss";

const DetectionTypeButton = ({
  name,
  color,
  probability,
  index,
  activeLayerTab,
  previousLayerTab,
  onClick
}) => {
  const handleLayerButtonClick = () => {
    onClick(index);
  };

  const isActive = activeLayerTab === index;

  return (
    <div className={styles["layer-button"]} onClick={handleLayerButtonClick}>
      <div
        className={styles["layer-button__info"]}
        style={{ backgroundColor: color }}
      >
        {name} {probability && probability}%
      </div>
      <div
        className={classNames(styles["layer-button__underline"], {
          [styles["layer-button__active-underline"]]: isActive
        })}
        style={{
          transformOrigin: isActive
            ? previousLayerTab !== null && index > previousLayerTab
              ? "left"
              : "right"
            : previousLayerTab !== null && previousLayerTab > activeLayerTab
            ? "left"
            : "right"
        }}
      />
    </div>
  );
};

export default DetectionTypeButton;
