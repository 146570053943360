import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";
import ProgressBar from "components/buttons/ProgressBar/ProgressBar";

import CheckIcon from "assets/icons/check.svg";

import styles from "./success-modal.module.scss";

interface SuccessModalInterface {
  title?: string;
  description?: string;
  isDisableClose?: boolean;
  acceptButtonLabel?: string;
  acceptButtonAction?: () => void;
  isWithProgress?: boolean;
}

const SuccessModal = () => {
  const {
    title,
    description,
    isDisableClose,
    acceptButtonLabel,
    acceptButtonAction,
    isWithProgress
  } = useSelector<RootStateInterface, SuccessModalInterface>(
    (state) => state.modal.modalProps
  );

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
    >
      <div className={styles["success-modal"]}>
        <div className={styles["success-modal__text-container"]}>
          <h2 className={styles["success-modal__title"]}>{title}</h2>
          <div className={styles["success-modal__description-container"]}>
            <img src={CheckIcon} alt="Done" />
            <h4 className={styles["success-modal__description"]}>
              {description ?? "Success"}
            </h4>
          </div>
          {isWithProgress && <ProgressBar value={1} max={1} />}
        </div>
        <div className={styles["success-modal__controls-wrapper"]}>
          <PrimaryButton
            type="button"
            onClick={acceptButtonAction}
            textSize={14}
          >
            {acceptButtonLabel ?? "Done"}
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default SuccessModal;
