import classNames from "classnames";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { LayerAlias, LAYERS } from "constant";
import features from "features";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";
import ExpandBlock from "components/ExpandBlock/ExpandBlock";
import File from "components/File/Files";
import FormField from "components/inputs/FormField/FormField";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import MapLayer from "../MapLayer/MapLayer";

import ExpandedLayersIcon from "assets/icons/expanded-layers.svg";
import LayersIcon from "assets/icons/layers.svg";
import ReportIcon from "assets/icons/report.svg";

import styles from "./map-layers.module.scss";

const MapLayers = ({ layers, aries, setView, points }) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const [isTransitionEnd, setIsTransitionEnd] = useState(true);

  const handleToggleOpenState = useCallback(() => {
    setIsOpen((prevState) => !prevState);
    setIsTransitionEnd(false);
  }, []);

  const handleTransitionEnd = useCallback((e) => {
    if (e.propertyName === "max-height") {
      setIsTransitionEnd(true);
    }
  }, []);

  const handleFormSubmit = useCallback(
    (alias: LayerAlias) => (values) => {
      dispatch(
        features.map.actions.createLayerRequest({
          params: { id, alias },
          fields: values,
          onSuccess: (res) => {
            dispatch(features.modal.actions.hideModal());

            const center = res.center;
            if (center) setView([res.center[1], res.center[0]], res.center[2]);

            const closeKey = "SUCCESS_CREATE_LAYER";

            dispatch(
              features.modal.actions.showModal({
                modalType: "SUCCESS",
                closeKey,
                modalProps: {
                  title: `Add ${LAYERS[alias].name}`,
                  acceptButtonAction: () =>
                    dispatch(
                      features.modal.actions.hideModal("SUCCESS_CREATE_LAYER")
                    )
                }
              })
            );
          },
          onError: (res) => {
            console.log(res);
          }
        })
      );
    },
    [dispatch, id, setView]
  );

  const addLayerAction = useCallback(
    (alias: LayerAlias) => () => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: `Add ${LAYERS[alias].name}`,
            description:
              alias === LayerAlias.THERMAL
                ? "Please select a file."
                : "Please make sure to use the task link from ODM, not the project link.",
            warningDescription: true,
            initialValues: {
              ...(alias === LayerAlias.THERMAL
                ? { file: undefined }
                : { link: "" })
            },
            onSubmit: handleFormSubmit(alias),
            validationSchema: Yup.object().shape({
              ...(alias === LayerAlias.THERMAL
                ? { file: Yup.mixed().required("File is required") }
                : { link: Yup.string().required("Link is required") })
            }),
            acceptButtonLabel: "Save",
            formContentWithContext: (setValue, values) => (
              <>
                {alias === LayerAlias.THERMAL ? (
                  <>
                    <File fileName={values.file?.name ?? "Name zip file"} />
                    <UploadFiles
                      title="Upload zip"
                      handleFileChange={(event: any) => {
                        setValue("file", event.target.files[0]);
                      }}
                    />
                  </>
                ) : (
                  <FormField
                    name="link"
                    placeholder="https://example.com/feed.xml"
                  />
                )}
              </>
            )
          }
        })
      );
    },
    [dispatch, handleFormSubmit]
  );

  const startInference = useCallback(
    (alias: LayerAlias) => () => {
      if (alias === LayerAlias.SENSOR_FUSION)
        dispatch(
          features.map.actions.startSensorFusionRequest({
            params: { id, alias }
          })
        );
      else
        dispatch(
          features.map.actions.startInferenceRequest({
            params: { id, alias }
          })
        );
    },
    [dispatch, id]
  );

  const goToReport = useCallback(() => {
    navigate("report");
  }, [navigate]);

  return (
    <div
      className={classNames(styles["layers"], {
        [styles["layers--active"]]: isOpen
      })}
    >
      <div className={styles["layers__button"]}>
        <div
          className={styles["layers__button-small-container"]}
          onClick={handleToggleOpenState}
        >
          <span className={styles["layers__button-label"]}>Layers</span>
          <div className={styles["layer__button-icon"]}>
            <img
              className={styles["icon"]}
              src={LayersIcon}
              alt="layers-button"
            />
            <img
              className={styles["icon--active"]}
              src={ExpandedLayersIcon}
              alt="layers-button"
            />
          </div>
        </div>
        <div
          className={classNames(styles["layers-wrapper"], {
            [styles["layers-wrapper--scroll"]]: isOpen && isTransitionEnd
          })}
          onTransitionEnd={handleTransitionEnd}
        >
          <div className={styles["layers-content"]}>
            <div className={styles["layers-content"]}>
              {layers.map((layer, index) => (
                <div
                  className={styles["layers-container"]}
                  key={`layer-${index}`}
                >
                  <MapLayer
                    {...layer}
                    startInference={startInference(layer.alias)}
                    addLayerAction={addLayerAction(layer.alias)}
                    isOnlyStart={layer.alias === LayerAlias.SENSOR_FUSION}
                  />
                </div>
              ))}
            </div>
            {layers.length > 0 && (
              <ExpandBlock title="Areas">
                <div className={styles["layers-container"]}>
                  {aries.map((layer, index) => (
                    <MapLayer
                      {...layer}
                      key={`layer-${index}`}
                      isPolygon
                      isInit
                    />
                  ))}
                </div>
              </ExpandBlock>
            )}
            <ExpandBlock title="Other">
              <div className={styles["layers-container"]}>
                <ShowHideButton
                  title={"Photos"}
                  onClick={() => {}}
                  isActive={false}
                  isDisabled={true}
                />
              </div>
            </ExpandBlock>
            {layers.find((item) => item.alias === LayerAlias.RGB).isActive && (
              <ExpandBlock title="Point">
                <div className={styles["layers-container"]}>
                  {points.length > 0 ? (
                    points.map((item) => (
                      <ShowHideButton
                        title={item.label}
                        icon={item.icon}
                        onClick={item.onClick}
                        isActive={item.isActive}
                        isDisabled={
                          !layers.find((item) => item.alias === LayerAlias.RGB)
                            .isActive
                        }
                        key={`btn-${item.label}`}
                      />
                    ))
                  ) : (
                    <label className={styles["no-points"]}>
                      No points added.
                    </label>
                  )}
                </div>
              </ExpandBlock>
            )}
            <ExpandBlock title="Action">
              <div className={styles["layers-container"]}>
                <CommonButton
                  type="button"
                  icon={ReportIcon}
                  onClick={goToReport}
                >
                  Create report
                </CommonButton>
              </div>
            </ExpandBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapLayers;
