import cn from "classnames";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import features from "features";

import IconButton from "components/buttons/IconButton/IconButton";

import CloseSmallIcon from "assets/icons/close-small.svg";

import styles from "./modal-wrapper.module.scss";

interface ModalWrapperInterface {
  children: any;
  className?: string | Modal.Classes | undefined;
  overlayClassName?: string | Modal.Classes | undefined;
  isDisableClose?: boolean;
  onHide?: () => void;
}

const ModalWrapper = ({
  children,
  className,
  overlayClassName,
  isDisableClose,
  onHide
}: ModalWrapperInterface): JSX.Element => {
  const dispatch = useDispatch();

  const onClose = () => {
    if (onHide) onHide();
    if (!isDisableClose) dispatch(features.modal.actions.hideModal());
  };

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={cn(styles["react-modal"], className)}
      overlayClassName={cn(styles["react-modal-overlay"], overlayClassName)}
      ariaHideApp={false}
      bodyOpenClassName="overflow-hidden"
      preventScroll={true}
    >
      <div className={styles["react-modal__wrapper"]}>
        {!isDisableClose && (
          <IconButton
            icon={CloseSmallIcon}
            className={styles["react-modal__close-btn"]}
            alt="close"
            onClick={onClose}
          />
        )}
        <div className={styles["react-modal__body"]}>{children}</div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
