//Method was created to fix default .toFixed
//0.855.toFixed(2) return 0.85 but customToFixed return 0.86 4,791
export const customToFixed = (
  number: number,
  fixedLength: number,
  withZero?: boolean
): number | string => {
  const initialDecimals = Number.isInteger(number)
    ? 0
    : number.toString().split(".")[1].length;
  const decimals = `1${"0".repeat(fixedLength)}`;
  let roundedNumber =
    Math.round(number * parseInt(decimals)) / parseInt(decimals);
  const newDecimals = Number.isInteger(roundedNumber)
    ? 0
    : roundedNumber.toString().split(".")[1].length;

  if (newDecimals > initialDecimals) {
    roundedNumber = parseFloat(roundedNumber.toFixed(initialDecimals));
  }

  roundedNumber = +roundedNumber;

  return withZero ? roundedNumber.toFixed(fixedLength) : roundedNumber;
};

export const squareMetersToHectares = (squareMeters: number) => {
  return squareMeters / 10000;
};
