//TODO refactoring
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import { DEFAULT_LAYERS_LIST, LayerAlias } from "constant";
import features from "features";
import { RootStateInterface } from "reducer";
import { ImageDetectionStateInterface } from "../ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import ZoomBtns from "components/ZoomBtns/ZoomBtns";

import styles from "./image-detection-page.module.scss";

interface PositionInterface {
  x: number;
  y: number;
}

const ImageDetection = () => {
  const dispatch = useDispatch();

  const canvasRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [prevImages, setPrevImages] = useState({
    [LayerAlias.RGB]: null,
    [LayerAlias.MULTISPECTRAL]: null
  });
  const [scale, setScale] = useState<number>(1);
  const [origin, setOrigin] = useState<PositionInterface>({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState<PositionInterface>({ x: 0, y: 0 });
  const [drawnImageWidth, setDrawnImageWidth] = useState<number>(0);
  const [drawnImageHeight, setDrawnImageHeight] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [activeLayer, setActiveLayer] = useState<LayerAlias>(LayerAlias.RGB);

  const { isUploadingImageInferenceLoading, detections, uploadProgress } =
    useSelector<RootStateInterface, ImageDetectionStateInterface>(
      (state) => state.imageDetection
    );

  const handleFileChange = useCallback(
    (e) => {
      const files = e.target.files;
      if (files.length) {
        const file = files[0];
        setSelectedFile(file);

        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const canvas = canvasRef.current;
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;

          setOrigin({
            x: 0,
            y: 0
          });
          setStartPos({
            x: 0,
            y: 0
          });
          setScale(1);

          setImage(img);
        };
      }
      dispatch(
        features.imageDetection.actions.resetDetections({ alias: activeLayer })
      );
    },
    [activeLayer, dispatch]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toastr.error("Error", "Please select a file before upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    const closeKey = "INFERENCE_SUCCESS";

    dispatch(
      features.imageDetection.actions.fetchUploadImageInferenceRequest({
        params: { alias: activeLayer },
        fields: { file: selectedFile },
        onSuccess: (res) => {
          if (res?.length === 0) toastr.error("Error", "No detections.");
          else {
            dispatch(
              features.modal.actions.showModal({
                modalType: "SUCCESS",
                modalProps: {
                  title: "Complete",
                  description: "Success",
                  acceptButtonAction: () =>
                    dispatch(features.modal.actions.hideModal(closeKey)),
                  isWithProgress: true
                },
                closeKey
              })
            );
          }
        }
      })
    );
  };

  const getBoxCoordinates = useCallback(
    (box) => ({
      x1: box.x1 * drawnImageWidth,
      y1: box.y1 * drawnImageHeight,
      x2: box.x2 * drawnImageWidth,
      y2: box.y2 * drawnImageHeight
    }),
    [drawnImageHeight, drawnImageWidth]
  );

  const drawBoundingBox = (ctx, x1, y1, x2, y2) => {
    ctx.strokeStyle = "#CCFF00";
    ctx.lineWidth = 2;
    ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
  };

  const drawLabel = (ctx, label, confidence, x1, y1) => {
    const padding = 5;
    const text = `Label: ${label}, Conf: ${confidence.toFixed(2)}`;
    const width = ctx.measureText(text).width;
    ctx.fillStyle = "#18181B";
    ctx.fillRect(
      x1 - padding,
      y1 - padding - 20,
      width + padding * 2,
      20 + padding * 2
    );
    ctx.fillStyle = "white";
    ctx.font = "14px Inter";
    ctx.fillText(text, x1 + padding, y1 - padding);
  };

  const drawLabels = useCallback(
    (detections) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      if (drawnImageWidth && drawnImageHeight) {
        detections.forEach((det) => {
          const { x1, y1, x2, y2 } = getBoxCoordinates(det.box);
          drawBoundingBox(ctx, x1, y1, x2, y2);
          drawLabel(ctx, det.name, det.confidence, x1, y1);
        });
      }
    },
    [drawnImageHeight, drawnImageWidth, getBoxCoordinates]
  );

  const clearCanvas = (ctx, canvas) => {
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
  };

  const calculateDrawDimensions = (canvas, image) => {
    const canvasRatio = canvas.width / canvas.height;
    const imageRatio = image.width / image.height;
    let drawWidth, drawHeight;

    if (canvasRatio > imageRatio) {
      drawHeight = canvas.height;
      drawWidth = image.width * (canvas.height / image.height);
    } else {
      drawWidth = canvas.width;
      drawHeight = image.height * (canvas.width / image.width);
    }

    return { drawWidth, drawHeight };
  };

  const drawImage = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    clearCanvas(ctx, canvas);
    ctx.setTransform(scale, 0, 0, scale, origin.x, origin.y);
    if (image) {
      const { drawWidth, drawHeight } = calculateDrawDimensions(canvas, image);
      setDrawnImageWidth(drawWidth);
      setDrawnImageHeight(drawHeight);
      ctx.drawImage(image, 0, 0, drawWidth, drawHeight);
    }
  }, [image, origin, scale]);

  const handleWheel = (e) => {
    const zoomFactor = 0.002;
    const zoom =
      e.deltaY < 0
        ? 1 + zoomFactor * Math.abs(e.deltaY)
        : 1 - zoomFactor * Math.abs(e.deltaY);
    const newScale = Math.min(Math.max(scale * zoom, 0.5), 4);

    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = (e.clientX - rect.left - origin.x) / scale;
    const mouseY = (e.clientY - rect.top - origin.y) / scale;

    const newOriginX = origin.x - mouseX * (newScale - scale);
    const newOriginY = origin.y - mouseY * (newScale - scale);

    setOrigin({ x: newOriginX, y: newOriginY });
    setScale(newScale);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPos({ x: e.clientX - origin.x, y: e.clientY - origin.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setOrigin({ x: e.clientX - startPos.x, y: e.clientY - startPos.y });
    }
  };

  const handleMouseUp = () => setIsDragging(false);
  const handleMouseOut = () => setIsDragging(false);

  const onChangeLayer = useCallback(
    (alias: LayerAlias) => () => {
      const savedData = prevImages[alias];
      const isShouldSaveImage = image;

      if (alias === activeLayer) return;

      setSelectedFile(null);
      setImage(null);

      if (savedData) {
        setImage(savedData.image);
        setSelectedFile(savedData.selectedFile);
      }
      if (isShouldSaveImage) {
        setPrevImages((prevImages) => {
          return { ...prevImages, [activeLayer]: { image, selectedFile } };
        });
      }

      setActiveLayer(alias);
    },
    [activeLayer, image, prevImages, selectedFile]
  );

  useEffect(() => {
    drawImage();
    if (detections[activeLayer].length) drawLabels(detections[activeLayer]);
  }, [scale, origin, image, drawImage, detections, drawLabels, activeLayer]);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 0);
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isUploadingImageInferenceLoading]);

  useEffect(() => {
    if (isUploadingImageInferenceLoading)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PROGRESS",
          modalProps: {
            title: "Analyzing",
            description:
              uploadProgress === 100
                ? "Inference process is running..."
                : "Uploading file",
            isDisableClose: true,
            progressValue: uploadProgress,
            modalActionButton: {
              label: "Next",
              isDisable: true
            }
          }
        })
      );
    else dispatch(features.modal.actions.hideModal());
  }, [dispatch, isUploadingImageInferenceLoading, uploadProgress]);

  return (
    <div className={styles["image-detection-page"]}>
      <form
        className={styles["image-detection-page__form"]}
        onSubmit={handleSubmit}
      >
        <UploadFiles handleFileChange={handleFileChange} />
        <CommonButton type="submit">Start inference</CommonButton>
      </form>
      <div className={styles["image-detection-page__type"]}>
        {DEFAULT_LAYERS_LIST.slice(0, 2).map((layer, i) => (
          <CommonButton
            className={
              activeLayer === layer.alias
                ? styles["layer--active"]
                : styles["layer"]
            }
            onClick={onChangeLayer(layer.alias)}
            key={`layer-${i}`}
          >
            {layer.name}
          </CommonButton>
        ))}
      </div>
      <canvas
        ref={canvasRef}
        data-testid="canvas"
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseOut}
      />
      <ZoomBtns
        zoomIn={() =>
          handleWheel({
            deltaY: -200,
            clientX: canvasRef.current.width / 2,
            clientY: canvasRef.current.height / 2
          })
        }
        zoomOut={() =>
          handleWheel({
            deltaY: 200,
            clientX: canvasRef.current.width / 2,
            clientY: canvasRef.current.height / 2
          })
        }
      />
    </div>
  );
};

export default ImageDetection;
