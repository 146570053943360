import cn from "classnames";

import styles from "./map-marker-icon.module.scss";

interface MapMarkerIconInterface {
  color: string;
  probability: number;
  isActive?: boolean;
  markerName: string;
}

const MapMarkerIcon = ({
  color,
  isActive,
  probability,
  markerName
}: MapMarkerIconInterface) => {
  return (
    <div
      className={cn(styles["marker"], { [styles["marker--active"]]: isActive })}
      data-testid="marker"
    >
      {markerName && (
        <span
          style={{ backgroundColor: color }}
          className={cn(styles["marker__text"], styles["marker__mine-name"])}
        >
          {markerName}
        </span>
      )}
      <span
        style={{ backgroundColor: color }}
        className={cn(styles["marker__text"], styles["marker__probability"])}
      >
        {Math.round(probability * 100)}%
      </span>
      <div className={styles["pin"]} style={{ backgroundColor: color }} />
    </div>
  );
};
export default MapMarkerIcon;
