import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { LayerAlias, MULTISPECTRAL_OPTIONS } from "constant";
import features from "features";
import { MapLayerInterface as MapLayerDataInterface } from "scenes/MapPage/ducks";

import Range from "components/Range/Range";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";
import MapActionBtns from "../MapActionBtns/MapActionBtns";

import styles from "./map-layer.module.scss";

interface MapLayerInterface extends MapLayerDataInterface {
  isPolygon?: boolean;
  isInit?: boolean;
  startInference?: () => void;
  addLayerAction?: () => void;
  isOnlyStart?: boolean;
}

const MapLayer = ({
  name,
  isActive,
  alias,
  params,
  opacity,
  isPolygon,
  startInference,
  addLayerAction,
  isInit,
  rescale_max,
  rescale_min,
  isOnlyStart
}: MapLayerInterface) => {
  const dispatch = useDispatch();

  const multispectralOptions = useMemo(
    () => MULTISPECTRAL_OPTIONS(rescale_min, rescale_max),
    [rescale_max, rescale_min]
  );

  const onSetLayerIsActive = useCallback(
    (isActive: boolean) => () => {
      isPolygon
        ? dispatch(features.map.actions.toggleAriaIsActive(alias))
        : dispatch(
            features.map.actions.setLayerIsActive({
              params: { alias },
              fields: { isActive }
            })
          );
    },
    [alias, dispatch, isPolygon]
  );

  const onLayerParamsChange = useCallback(
    (params: string, alias: string) => () => {
      dispatch(features.map.actions.setLayerParams({ params, alias }));
    },
    [dispatch]
  );

  const onChangeOpacity = useCallback(
    (alias) => (opacity) => {
      dispatch(features.map.actions.setLayerOpacity({ opacity, alias }));
    },
    [dispatch]
  );

  return (
    <div className={styles["layer-wrapper"]}>
      <ShowHideButton
        title={name}
        onClick={onSetLayerIsActive(!isActive)}
        isActive={isActive}
        isDisabled={!isInit && !isOnlyStart}
      />
      {!isPolygon && !isOnlyStart && (
        <Range
          value={opacity ?? 100}
          containerClassName={styles["layer__range-container"]}
          isDisabled={!isInit || !isActive}
          onChange={onChangeOpacity(alias)}
        />
      )}
      {alias === LayerAlias.MULTISPECTRAL && (
        <div className={styles["multispectral__options"]}>
          {multispectralOptions.map((item) => {
            return (
              <button
                className={classNames(styles["multispectral__option"], {
                  [styles["multispectral__option--active"]]:
                    params === item.params && isActive
                })}
                onClick={onLayerParamsChange(item.params, alias)}
                key={`layer-param-${item.name}`}
                disabled={!isActive}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      )}

      {!isPolygon && (
        <div className={styles["layer__buttons-container"]}>
          <MapActionBtns
            inferenceAction={startInference}
            isLayerInit={isInit || isOnlyStart}
            action={isOnlyStart ? null : addLayerAction}
          />
        </div>
      )}
    </div>
  );
};
export default MapLayer;
