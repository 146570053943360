import cn from "classnames";
import { useEffect } from "react";

import useOutsideClick from "helpers/useOutsideClick";

import styles from "./dropdown-menu.module.scss";

interface DropdownMenuInterface {
  //TODO add interface
  options: any[];
  isActive: boolean;
  onClick: (option) => void;
}

const DropdownMenu = ({
  options,
  isActive,
  onClick
}: DropdownMenuInterface) => {
  const ref = useOutsideClick(onClick, isActive);
  const optionClick = (e, option) => {
    e.stopPropagation();
    onClick(option);
  };
  useEffect(() => {
    const handleResize = () => {
      const dropdown = ref.current;
      const rect = dropdown.getBoundingClientRect();
      const windowHeight = document.documentElement.scrollHeight;
      const windowWidth = document.documentElement.clientWidth;
      //IMPORTANT paddings stored in container mixin
      const containerPadding =
        windowWidth > 1300 ? 40 : windowWidth > 992 ? 20 : 10;

      if (rect.top + rect.bottom > windowHeight) {
        dropdown.style.bottom = `${
          dropdown.parentElement.clientHeight + rect.height + 6
        }px`;
      }

      if (rect.right > windowWidth) {
        dropdown.style.left = `${
          windowWidth - rect.right - containerPadding
        }px`;
      }
    };

    // Initial call to handleResize to set initial positions
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return (
    <div
      className={cn(styles["dropdown"], {
        [styles["dropdown--active"]]: isActive
      })}
      ref={ref}
    >
      {options.map((item, i) => {
        return (
          <div
            className={styles["option"]}
            key={`option${i}`}
            onClick={(e) => optionClick(e, item)}
          >
            {item.icon && (
              <div className={styles["option-icon"]}>
                <img src={item.icon} alt={`{item.label}-icon`} />
              </div>
            )}
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default DropdownMenu;
