const LogoForPDF = () => {
  return (
    <svg
      width="105"
      height="15"
      viewBox="0 0 105 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14.9542V1H13.8539L17.3129 3.88572V12.029L13.8539 14.9147H3V14.9542ZM7.21434 12.2662H12.0251L13.2178 11.2779V4.67633L12.0251 3.68807H7.21434V12.2662Z"
        fill="#18181B"
      />
      <path
        d="M40.7699 14.9542L38.2651 12.8591V3.09511L40.7699 1H50.5106L53.0153 3.09511V12.8196L50.5106 14.9147H40.7699V14.9542ZM43.3144 12.2662H48.0456L48.8408 11.5942V4.36009L48.0058 3.68807H43.3144L42.5192 4.36009V11.5942L43.3144 12.2662Z"
        fill="#18181B"
      />
      <path
        d="M56.832 14.9542V1H67.9245L70.4292 3.09511V8.47125L67.9245 10.5664H61.0464V14.9147H56.832V14.9542ZM61.0464 7.87829H65.5788L66.3739 7.20628V4.36009L65.5788 3.68807H61.0464V7.87829Z"
        fill="#18181B"
      />
      <path
        d="M73.4907 14.9542V1H77.7051V12.0685H84.1061L83.0326 14.9542H73.4907Z"
        fill="#18181B"
      />
      <path
        d="M97.8228 1H90.9845L86.0942 14.9542H90.5471L91.2628 12.5429L96.9084 10.6454L97.3855 12.1476L98.2204 14.9147H102.673L97.8228 1ZM92.9724 7.00863L94.0061 3.64854H94.8012L96.1132 7.99689L92.2567 9.30139L92.9724 7.00863Z"
        fill="#18181B"
      />
      <path
        d="M31.1089 14.9542H35.363L32.8185 9.69669L34.7269 8.11548V3.09511L32.1824 1H20.8911V14.9542H25.1055V11.5546L28.962 10.2501L31.1089 14.9542ZM25.1055 3.68807H29.8366L30.6318 4.36009V6.81097L25.1055 8.6689V3.68807Z"
        fill="#18181B"
      />
    </svg>
  );
};

export default LogoForPDF;
