import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";
import Preloader from "components/Preloader/Preloader";

import styles from "./preloader-modal.module.scss";

interface PreloaderModalInterface {
  title?: string;
  description?: string;
  loading: string;
  isDisableClose?: boolean;
}

const PreloaderModal = () => {
  const { title, description, loading, isDisableClose } = useSelector<
    RootStateInterface,
    PreloaderModalInterface
  >((state) => state.modal.modalProps);

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
    >
      <div className={styles["preloader-modal"]}>
        <div className={styles["preloader-modal__content"]}>
          {title?.length > 0 && (
            <h2 className={styles["preloader-modal__title"]}>{title}</h2>
          )}
          {description?.length > 0 && (
            <p className={styles["preloader-modal__description"]}>
              {description}
            </p>
          )}
        </div>
        <Preloader loading={loading} />
      </div>
    </ModalWrapper>
  );
};
export default PreloaderModal;
