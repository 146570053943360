import FolderIcon from "assets/icons/folder-check.svg";

import styles from "./file.module.scss";

interface UploadFilesInterface {
  fileName: string;
}

const File = ({ fileName }: UploadFilesInterface) => (
  <div className={styles["file"]}>
    <img src={FolderIcon} alt="folder-icon" />
    <label>{fileName}</label>
  </div>
);
export default File;
