import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import features from "features";

import LightButton from "components/buttons/LightButton/LightButton";
import { POINTS } from "constant";
import MapPointModalForm from "./components/MapPointModalForm/MapPointModalForm";

import TrashIcon from "assets/icons/trash.svg";
import useDeleteMarker from "hooks/useDeleteMarker";

const DEFAULT_INITIAL_VALUES = {
  coordinates: [],
  object_diameter: "",
  name: "",
  object_type: null
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  object_diameter: Yup.string().required("Diameter is required"),
  object_type: Yup.object().required("Type object is required")
});

const usePointHandler = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const onDeleteMarker = useDeleteMarker();

  const [activePointData, setActivePointData] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const parseFields = useCallback((data) => {
    const { name, object_type, object_diameter, coordinates } = data;
    const geo_data = {
      coordinates: Object.values(coordinates),
      type: "Point"
    };

    return {
      name,
      object_type: object_type.value,
      object_diameter,
      geo_data
    };
  }, []);
  const clearData = useCallback(() => {
    setActivePointData(null);
    setIsEdit(false);
    dispatch(features.map.actions.clearMarkerDetails());
  }, [dispatch]);

  const createPoint = useCallback(
    ({ onCreatePointSuccess, coordinates }) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Add point",
            description:
              "Add a description or mark the coordinates for a more precise location.",
            onSubmit: (e) => {
              const fields = parseFields(e);

              dispatch(
                features.map.actions.createMarkerRequest({
                  params: { projectId: id },
                  fields,
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    onCreatePointSuccess();
                  }
                })
              );
            },
            validationSchema,
            initialValues: {
              ...DEFAULT_INITIAL_VALUES,
              coordinates: { ...coordinates }
            },
            formContentWithContext: (setValue, values) => (
              <MapPointModalForm setValue={setValue} values={values} />
            )
          }
        })
      );
    },
    [dispatch, id, parseFields]
  );

  const viewPointDetails = useCallback((data) => {
    const parsedData = {
      ...data,
      name: data.name,
      object_type: POINTS[data.additional.object_type],
      object_diameter: data.additional.object_diameter,
      coordinates: {
        lat: data.geo_data.coordinates[0],
        lng: data.geo_data.coordinates[1]
      }
    };

    setActivePointData(parsedData);
  }, []);

  useEffect(() => {
    if (activePointData && !isEdit)
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Point",
            acceptButtonLabel: "Edit",
            onSubmit: () => {
              setIsEdit(true);
            },
            declineButtonAction: () => {
              clearData();
            },
            validationSchema,
            initialValues: {
              ...DEFAULT_INITIAL_VALUES,
              ...activePointData
            },
            additionalControlElements: [
              <LightButton
                textSize={14}
                key={"delete"}
                icon={TrashIcon}
                onClick={onDeleteMarker(activePointData.id)}
              >
                Delete
              </LightButton>
            ],
            formContentWithContext: (setValue, values) => (
              <MapPointModalForm setValue={setValue} values={values} isLock />
            )
          }
        })
      );
    if (activePointData && isEdit)
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Edit point",
            acceptButtonLabel: "Save",
            declineButtonLabel: "Reset",
            declineButtonAction: () => {
              setIsEdit(false);
            },
            onHide: clearData,
            onSubmit: (e) => {
              const fields = parseFields(e);

              dispatch(
                features.map.actions.updateMarkerRequest({
                  params: { projectId: id, markerId: activePointData.id },
                  fields,
                  onSuccess: clearData
                })
              );
            },
            validationSchema,
            initialValues: {
              ...DEFAULT_INITIAL_VALUES,
              ...activePointData
            },
            formContentWithContext: (setValue, values) => (
              <MapPointModalForm setValue={setValue} values={values} />
            )
          }
        })
      );
  }, [
    activePointData,
    clearData,
    dispatch,
    id,
    isEdit,
    onDeleteMarker,
    parseFields
  ]);

  return { createPoint, viewPointDetails };
};

export default usePointHandler;
